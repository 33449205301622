import { useEffect } from 'react'
import { navigate } from '@reach/router'

const CatchAllPage = (element) => {
  const siteLocales = JSON.parse(process.env.GATSBY_ALL_LOCALES)
  const urlLanguage = element.location?.pathname?.split('/')?.[1]?.toLowerCase()
  let currentLanguage = ''
  siteLocales.forEach((locale) => {
    if (locale === urlLanguage) {
      currentLanguage = locale
    }
  })
  useEffect(() => {
    if (!element.location?.pathname?.includes('/404/')) {
      if (currentLanguage) {
        navigate(`/${currentLanguage}/404/`)
      } else {
        navigate('/404/')
      }
    }
  }, [])

  return null
}
export default CatchAllPage